import React from "react";
import Home from "./components/Home";
import Experiences from "./components/Experiences";
import Rooms from "./components/Rooms";
import Navbar from "./components/Navbar";
import Island from "./components/Island";
import { ContactUs } from "./components/ContactUs";

function App() {
  return (
    <div>
      <Navbar />
      <Home />
      <Island />
      <Experiences />
      <Rooms />
      <ContactUs />
    </div>
  );
}

export default App;
