import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DoubleRoomInfo from "./components/DoubleRoomInfo";
import FamilyRoomInfo from "./components/FamilyRoomInfo";
import DeluxDoubleRoomInfo from "./components/DeluxDoubleRoomInfo";


ReactDOM.render(
  <Router>
    {/* <React.StrictMode> */}
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/rooms/double_room" element={<DoubleRoomInfo />} />
        <Route path="/rooms/family_room" element={<FamilyRoomInfo />} />
        <Route
          path="/rooms/deluxe_double_room"
          element={<DeluxDoubleRoomInfo />}
        />
      </Routes>
    {/* </React.StrictMode> */}
  </Router>,
  document.getElementById("root")
);
