import React from 'react';
import "./Imageslider.css"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import snorkeling1 from "./images/experience/snorkling/snorkling-1.JPG";
import snorkeling2 from "./images/experience/snorkling/snorkling-2.JPG";
import snorkeling3 from "./images/experience/snorkling/snorkling-3.JPG";


function SnorkelingSlider() {
  
    
  return(
   
    <Carousel className='image__slider__container' autoPlay={true} infiniteLoop={true} showThumbs={false} interval={2500} stopOnHover={false} showStatus={false}>
                <div>
                    <img src={`${snorkeling1}`} className='slider__img' alt="slider_image"/>
                  
                </div>
                <div>
                    <img src={`${snorkeling2}`}  className='slider__img' alt="slider_image"/>
                  
                </div>
                <div>
                    <img src={`${snorkeling3}`} className='slider__img' alt="slider_image"/>
                   
                </div>
            </Carousel>
  
  );
}

export default SnorkelingSlider;
