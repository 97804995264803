import React, { Component } from 'react';
import "./RoomSlider.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';


import img1 from "./images/rooms/double_room/double_room_1.jpg"
import img2 from "./images/rooms/double_room/double_room_2.jpg"
import img3 from "./images/rooms/double_room/double_room_3.jpg"
import img4 from "./images/rooms/double_room/double_room_4.jpg"
import img5 from "./images/rooms/double_room/double_room_5.jpg"




import AOS from "aos";

AOS.init({
  once: true,
  duration: 1000,
  offset: 300,
});

export class DoubleRoomSlider extends Component {
  render() {
    return (
      <div   data-aos="fade-up" data-aos-delay="100" className='room__info__slider'>
         <Carousel
        autoPlay={true}
        infiniteLoop={true}
        interval={3000} 
        stopOnHover={false}
        emulateTouch={true}
        showStatus={false}>
                <div>
                    <img src={img1} alt="double_room_img"/>
                </div>
                <div>
                    <img src={img2} alt="double_room_img"/>
                </div>
                <div>
                    <img src={img3} alt="double_room_img"/>
                </div>
                <div>
                    <img src={img4} alt="double_room_img"/>
                </div>
                <div>
                    <img src={img5} alt="double_room_img"/>
                </div>
              
            </Carousel>
      </div>
    )
  }
}

export default DoubleRoomSlider