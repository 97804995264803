import React from "react";
import "./Experiences.css";
import AOS from "aos";
import SnorkelingSlider from "./SnorkelingSlider";
import SurfingSlider from "./SurfingSlider";
import DivingSlider from "./DivingSlider";


AOS.init({
  once: true,
  duration: 1000,
  offset: 300,
});

const Experiences = () => {
 

  return (
    <div className="experiences" id="experiences">
      <h1 className="section__hedding" data-aos="fade-up" data-aos-delay="500">Experiences</h1>

      <div className="container">
        
        {/* <p className="intor" data-aos="fade-up" data-aos-delay="1">
          Unforgetable experience from staying in maldives
        </p> */}
{/* Snorkeling */}
        <h2 className="sub__hedding" data-aos="fade-up" data-aos-delay="300">
          Snorkeling
        </h2>
        <div className="content">
          <div data-aos="fade-up">
            <p>
            Adventure is calling! This activity takes you out to the deep blue sea for a day of snorkeling around Guraidhoo. You’ll get to see some of the most exquisite spots around Maldives that are otherwise inaccessible by land. Our guides will lead you out to these reefs and lagoons, while making sure you have an awesome time.
            </p>
          </div>
          <div data-aos="fade-up">
            <SnorkelingSlider />
          </div>
        </div>
{/* surfing */}
        <h2 className="sub__hedding" data-aos="fade-up">
          Surfing
        </h2>
        <div className="content">
         
          <div data-aos="fade-up">
            <p>
            The Maldives is a surfers paradise and Guraidhoo is no different. The small island of Guraidhoo is located on the western edge of the Maldives and is surrounded by exceptional and diverse break points to cater for every level of surfer. Considered by those in the know to be the best surf on its day, expect up to 5 meter waves with a fast reaction needed to properly ride this freight train.
            </p>
          </div>
          <div data-aos="fade-up" data-aos-delay="5">
            <SurfingSlider/>
          </div>
        </div>

{/* diving */}
        <h2 className="sub__hedding" data-aos="fade-up" >
         Diving
        </h2>
        <div className="content">
          
          <div data-aos="fade-up">
            <p>
            Dive into an adventure and explore the vibrant coral reefs of Guraidhoo. This remote island is a haven for divers with over 8 dive sites close by, including the KANDOOMA CAVE and KUDA GIRI. During your stay, you'll experience incredible diving.

            </p>
          </div>
          <div data-aos="fade-up" data-aos-delay="5">
            <DivingSlider/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experiences;
