import React from 'react';
import "./Imageslider.css"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import dive1 from "./images/experience/dive/dive-1.JPG";
import dive2 from "./images/experience/dive/dive-2.JPG";
import dive3 from "./images/experience/dive/dive-3.JPG";

function DivingSlider() {
  return (
    <Carousel className='image__slider__container' autoPlay={true} infiniteLoop={true} showThumbs={false} interval={2000} stopOnHover={false}  showStatus={false}>
    <div>
        <img src={`${dive1}`} className='slider__img' alt="slider_image"/>
      
    </div>
    <div>
        <img src={`${dive2}`}  className='slider__img' alt="slider_image"/>
      
    </div>
    <div>
        <img src={`${dive3}`} className='slider__img' alt="slider_image"/>
       
    </div>
</Carousel>
    
    );
}

export default DivingSlider;
