import React from "react";
import "./Home.css";
import { Link } from "react-scroll";
import AOS from "aos";
import Bg_video from "./images/experience/videos/video-1.MP4";

AOS.init({
  once: true,
  duration: 1,
  offset: 0,
});
const Home = () => {
  return (
    <div className="hero" id="home">
      <video id="background-video" autoPlay loop muted playsInline> 
        <source src={Bg_video} type="video/mp4" />
        <source src={Bg_video} type="video/webm" />
        <source src={Bg_video} type="video/ogg" />
        Your browser does not support the video tag.
      </video>
      <div className="content">
        <p>In a paradise state of mind</p>
        <Link
          className="button"
          to="experiences"
          spy={true}
          smooth={true}
          offset={-1}
          duration={500}
        >
          explore
        </Link>
      </div>
    </div>
  );
};

export default Home;
