import React from "react";
import AOS from "aos";
import "./Rooms.css";
import doubleRoom from "./images/rooms/Double-room.jpg";
import familyRoom from "./images/rooms/Family-room.jpg";
import deluxDoubleRoom from "./images/rooms/Delux-double-room.jpg";
import RoomCategoris from "./RoomCategoris";
import { Link } from "react-router-dom";

AOS.init({
  once: true,
  duration: 1000,
  offset: 300,
});

const Rooms = () => {
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className="rooms" id="rooms">
      <h1 className="section__hedding" data-aos="fade-up">
        OUR ROOMS
      </h1>
      <div className="container">
        <div className="room-content">
          {/* <p>varity of room available in our guest house fell free to choose</p> */}
          <div className="content">
            <div data-aos="fade-up" data-aos-delay="500" className="room__cards">
              <RoomCategoris
                roomName="Double Room"
                description="This double room has a balcony, executive lounge access and electric kettle."
                price="55 $"
                img={doubleRoom}
                imgAlt="family room image"
              />
              <Link to="rooms/double_room" className="button" onClick={goToTop}>
                Read more
              </Link>
             
            </div>
            <div data-aos="fade-up" data-aos-delay="500" className="room__cards">
              <RoomCategoris
                roomName="Family Room"
                description="This family room features a balcony, tea/coffee maker and view."
                price="85 $"
                img={familyRoom}
                imgAlt="family room image"
              />
              <Link to="rooms/family_room" className="button" onClick={goToTop}>
                Read more
              </Link>
            </div>
            <div data-aos="fade-up" data-aos-delay="500" className="room__cards">
              <RoomCategoris
                roomName="Deluxe Double Room"
                description="This double room has a balcony, air conditioning and dining area."
                price="115  $"
                img={deluxDoubleRoom}
                imgAlt="family room image"
              />
              <Link to="rooms/deluxe_double_room" className="button" onClick={goToTop}>
                Read more
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rooms;
