import React from 'react'

function RoomCategoris(props) {
  return (
    <div className="room-categoreis">
        <div className="card">
          <div className="room-img-cointainer">
          <h4>{props.roomName}</h4>
            <img src={props.img} alt={props.imgAlt} className="room-Img" />
          </div>

          <p>{props.description}</p>
          <p  className="price-tag">
            <span>{props.price}</span>
          </p>
        </div>
      </div>
  )
}

export default RoomCategoris

