import React from 'react';
import "./Imageslider.css"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import surfing1 from "./images/experience/surfing/surfing-1.jpg";
import surfing2 from "./images/experience/surfing/surfing-2.jpg";
import surfing3 from "./images/experience/surfing/surfing-3.jpg";


function SurfingSlider() {

    
  return(
    <Carousel className='image__slider__container' autoPlay={true} infiniteLoop={true} showThumbs={false} interval={2000} stopOnHover={false}  showStatus={false}>
                <div>
                    <img src={`${surfing1}`} className='slider__img' alt="slider_image"/>
                  
                </div>
                <div>
                    <img src={`${surfing2}`}  className='slider__img' alt="slider_image"/>
                  
                </div>
                <div>
                    <img src={`${surfing3}`} className='slider__img' alt="slider_image"/>
                   
                </div>
            </Carousel>

  );
}

export default SurfingSlider;
