import React from 'react'; //import useState when using
import "./RoomsInfo.css";
// import ReactPannellum from "react-pannellum";
// import test from "./images/rooms/test.jpeg";
import AOS from "aos";
import DeluxDoubleRoomSlider from './DeluxDoubleRoomSlider';

AOS.init({
  once: true,
  duration: 1000,
  offset: 300,
});



function DeluxDoubleRoomInfo() {

  // const [isActive, setActive] = useState("false");
  // const handleToggle = () => {
  //   setActive(!isActive);
  // };

  // const [click, setClick] = useState(false);
  // const handleClick = () => setClick(!click);



  
// const config = {
//   autoLoad: true,
// };
  return (
    <div className="room__info__container">
    <h1 className="room__info__hedding">Delux Double room</h1>
      <div className="room__info">
      {/* {isActive ?(<button onClick={handleToggle} className="button">360 view</button>):(<button onClick={handleToggle} className="button">slider</button>)}
      
         {isActive ? (
          <DeluxDoubleRoomSlider/>
        ) : (
          <div className="pannellum__container" data-aos="fade-up" data-aos-delay="100">
            <ReactPannellum
              className="panaroma__img"
              id="1"
              sceneId="firstScene"
              imageSource={test}
              config={config}
            />
          </div>
        )} */}
         <DeluxDoubleRoomSlider/>
    </div>
    <div className="container">
      <div className="info">
        <div className="info__list__card">
          <h5>General Info</h5>
          <ul className="info__items">
            <li>1 king bed</li>
          <li>Balcony</li>
              <li>Ocean view</li>
              <li>Private Bathroom</li>
              <li>Air conditioning</li>
              <li>Terrace</li>
              <li>Minibar</li>
              <li>Flat-screen TV</li>
          </ul>
        </div>

       

        <div className="info__list__card">
          <h5>In bathroom</h5>
          <ul className="info__items">
            <li>Toiletries</li>
            <li>Toilet</li>
            <li>Bath & shower</li>
            <li>Towels</li>
            <li>Hairdryer</li>
            <li>Toilet Paper</li>
          </ul>
        </div>

        <div className="info__list__card">
          <h5>Included</h5>
          <ul className="info__items">
            <li>Linen</li>
            <li>Hand Sanitizer</li>
            <li>Air conditioning</li>
            <li>Iron</li>
            <li>Electric kettle</li>
            <li>Balcony</li>
            <li>Clothes rack</li>
            <li>Wake up service</li>
            <li>Wardrobe /closet</li>
            <li>Tea / coffee maker</li>
            <li>Tea / coffee maker</li>
            <li>Safety deposit box</li>
            <li>Fan</li>
            <li>Tumble dryer</li>
            <li>Terrace</li>
            <li>Dry rack for clothing</li>
            <li>Executive lounge access</li>
          </ul>
        </div>
        <div className="info__list__card">
          <h5>Meal Plane</h5>
          <ul className="info__items">
            <li>Breakfast: Not available</li>
          </ul>
        </div>

        <div className="info__list__card">
          <h5>View</h5>
          <ul className="info__items">
            <li>Sea view</li>
          </ul>
        </div>
      </div>
    </div>
  
    
    {/* <button className="button">make a reservation for 30 $</button> */}
  </div>
  );
}

export default DeluxDoubleRoomInfo;
