import React from "react";
import { useForm } from 'react-hook-form';
import "./ContactUs.css";
import { Link } from "react-router-dom";

import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

export const ContactUs = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();

  const toastifySuccess = () => {
    toast('Form sent!', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: 'submit-feedback success',
      toastId: 'notifyToast'
    });
  };

// Function called on submit that uses emailjs to send email of valid contact form
const onSubmit = async (data) => {
  // Destrcture data object
  const { name, email, message } = data;
  try {
    const templateParams = {
      name,
      email,
      message
    };

    await emailjs.send(
      'service_zkmb8jw',
      'template_chwcpz7',
      templateParams,
      '5ypqw1tIc_cSb_nnX'
    );

    reset();
    toastifySuccess();
  } catch (e) {
    console.log(e);
  }
};
  

  return (
    <div className="contact__us" id="contactUs">
    
        <h1 className="section__hedding">CONTACT US</h1>

        <div className="contact-wrapper">
        <form id='contact-form' className="form-horizontal" onSubmit={handleSubmit(onSubmit)} noValidate>
                {/* Row 1 of form */}
                <div className='row form-group'>
                  <div className='col-sm-12'>
                  {errors.name && <span className='errorMessage'>{errors.name.message}</span>}
                    <input
                      type='text'
                      name='name'
                      {...register('name', {
                        required: { value: true, message: 'Please enter your name' },
                        maxLength: {
                          value: 30,
                          message: 'Please use 30 characters or less'
                        }
                      })}
                      className="form-control"
                      placeholder='Name'
                    ></input>
                  </div>
                </div>
                {/* Row 2 of form */}
                <div className='row form-group'>
                  <div className='col-sm-12'>
                  {errors.email && (
                      <span className='errorMessage'>invalid email address</span>
                    )}
                    <input
                      type='email'
                      name='email'
                      {...register('email', {
                        required: true,
                        pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                      })}
                      className="form-control"
                      placeholder='Email address'
                    ></input>
                  </div>
               
                  </div>
                {/* Row 3 of form */}
                <div className='row form-group'>
                  <div className='col-sm-12'>
                  {errors.message && <span className='errorMessage'>Please enter a message</span>}
                    <textarea
                      rows={3}
                      name='message'
                      {...register('message', {
                        required: true
                      })}
                      className="form-control"
                      placeholder='Message'
                    ></textarea>
                  </div>
                </div>
                <button className='button' type='submit'>
                  Send
                </button>
              </form>
              <ToastContainer />

          <div className="direct-contact-container">
            <ul className="contact-list">
              <li className="list-item">
                <i className="fa fa-map-marker fa-1x">
                  <span className="contact-text place">Male' atoll, K.guraidhoo</span>
                </i>
              </li>

              <li className="list-item">
                <i className="fa fa-phone fa-1x">
                  <span className="contact-text phone">
                    <a href="tel:+960 7961167" title="Give me a call">
                      +960 7961167
                    </a>
                  </span>
                </i>
              </li>

              <li className="list-item">
                <i className="fa fa-phone fa-1x">
                  <span className="contact-text phone">
                      <a href="tel:+9609834373" title="Give me a call" >
                      +960 9834373
                    </a>
                  </span>
                </i>
              </li>

            
            </ul>

            <hr />
            <ul className="social-media-list">
              <li>
                {/* <a href="#" target="_blank" className="contact-icon"> */}
                  <Link to="tel:9834373" target="_blank" className="contact-icon">
                  <i className="fa fa-whatsapp" aria-hidden="true"></i>
                {/* </a> */}
                </Link>
              </li>
              {/* <li>
              <Link to="#"  className="contact-icon">
                  <i className="fa fa-facebook" aria-hidden="true"></i>
                </Link>
              </li> */}
              <li>
                <Link to="https://www.instagram.com/hiraethislandhome/" className="contact-icon">
                  <i className="fa fa-instagram" aria-hidden="true"></i>
                </Link>
              </li>
            </ul>
            <hr />

            <div className="copyright">&copy; ALL OF THE RIGHTS RESERVED</div>
            <div className="copyright">HIRAETH ISLAND HOME </div>
            <div className="copyright">2022</div>
          </div>
        </div>
    </div>
  );
};
