import React, { useState } from "react";
import islandImg from "./images/island.jpg";
import AOS from "aos";
import "./Island.css";


AOS.init({
  once: true,
  duration: 1000,
  offset: 300,
});

const Island = () => {
  const [readMore, setReadMore] = useState(false);
  const [readLess, setReadLess] = useState(false);

  const extraContent = (
    <div>
      <div className="extra-content">
        <article className="extra-content-articales">
          <h4>People</h4>
          <p>
          The friendly population of Guraidhoo live in harmony with one another. Their work ethic is second to none, and they are always keen to offer directions or assistance. The island's tranquility offers a nice welcome respite after the rush of capital city of the Maldives. The only music heard on Guraidhoo is that of the waves gently lapping against the island shores.

          </p>
        </article>
        <article className="extra-content-articales">
          <h4>Environment </h4>
          <p>
          The Maldives are made up of 1,190 tiny islands and lie across 26 atolls in the Indian Ocean. Lying just above the equator, the Maldives enjoys a tropical climate that is consistently hot and often humid all year round, with two distinct seasons: a wet season that runs from April until November, and a dry season between December and March. During this time the rainfall amounts are at their lowest, accompanied with the longest hours of sunshine.
          </p>
        </article>

        <article className="extra-content-articales">
          <h4>Culture </h4>
          <p>
          Maldives is a unique destination that offers Indian Ocean island living at its finest. Imagine waking to the sound of waves lapping on pristine sandy beaches and that’s about as idyllic as it gets. The official language is Dhivehi, a South Asian language with strong Arabic influence. The locals are generally warm and friendly, and English is widely spoken in the areas popular with tourists.
          </p>
        </article>
      </div>
    </div>
  );
  return (
    <div className="about" id="island">
      <h1  className="section__hedding" data-aos="fade-up">ISLAND</h1>
      <div className="container">
      <img src={islandImg} alt="island__image"  data-aos="fade-up"/>
        <div className="col-2">
          {/* <span className="line"></span> */}
          <article className="about__container">
            <h2 className="sub__hedding" data-aos="fade-up">Guraidhoo Island</h2>{" "}
            <p data-aos="fade-up">
            A perfect place for a peaceful relaxation of the mind, Guraidhoo brings you amazing aquamarine water, colourful houses and coral chunks.
            </p>
            <button data-aos="fade-up" 
            className="button"
            onClick={() => {
              setReadMore(!readMore, setReadLess(!readLess));
            }}
          >
            {!readLess ? "Read more" : "Read less"}
          </button>
          </article>
          
        </div>
     
      </div>
      <div className="col-3">
       {readMore && extraContent}
        </div>
    </div>
  );
};

export default Island;
